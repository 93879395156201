<template>
  <div
    v-loading="loading"
    class="r-modal-card bdd-modal application-create-vehicle"
  >
    <div class="r-modal-card__controls">
      <r-button
        type="primary"
        :disabled="saveDisabled"
        @click="create"
      >
        Создать ТС
      </r-button>
      <r-button
        simple
        @click="close"
      >
        Отмена
      </r-button>
    </div>
    <attributes-list
      :model="model"
      :related="related"
      :source="source.vehicle"
      is-editing
    />
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import { potItemModel } from '../configs'
import { notifyFactory } from '@/utils'

export default {
  components: { attributesList },
  data() {
    return {
      loading: false,
      vehiclesUrl: this.$store.state.services.vehicles,
      info: null,
      source: {}
    }
  },
  computed: {
    related() {
      return this.$store.state.pot1.related
    },
    saveDisabled() {
      return !this.source.vehicle?.reg_number
    },
    model() {
      const vehicleFields = [
        'reg_number',
        'main_contractor_id',
        'driver_phone',
        'vehicle_type_id',
        'model_id',
        'driver_full_name'
      ]
      return potItemModel.filter(f => vehicleFields.includes(f.model))?.map(e => {
        return {
          ...e,
          read_only: false
        }
      })
    }
  },
  created() {
    const vehicle = {}
    this.model.forEach(m => {
      vehicle[m.model] = null
    })
    this.source = { vehicle }
  },
  methods: {
    async create() {
      this.loading = true

      try {
        const vehicleData = {
          ...this.source.vehicle,
          source: 'dispatcher'
        }
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: 'vehicle?format=cargo',
          data: vehicleData
        })
        const vehicle_id = data[0]?.id
        if (!vehicle_id) return

        this.$notify(
          notifyFactory(
            'success',
            'Новое транспортное средство успешно создано!'
          )
        )

        this.$emit('update-list', 'vehicle')
        this.close()
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
